import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Channel } from "../../models";
import Header from "../../components/Header";

function SearchGroup({
  handleSearch,
}: {
  handleSearch: (searchTerm: string) => void;
}) {
  return (
    <form className="max-w-md my-4">
      <label
        htmlFor="default-search"
        className="mb-2 text-sm font-medium text-gray-900 sr-only"
      >
        Search
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg
            className="w-4 h-4 text-gray-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
        <input
          type="search"
          className="block w-full p-4 ps-10 text-sm text-gray-900 border border-solid border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-400 focus:border-blue-500"
          placeholder="Search for a channel"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
    </form>
  );
}

function ArtistCard({ channel }: { channel: Channel }) {
  const channelUrl = channel.channelAlias
    ? channel.channelAlias
    : channel.channelCode;

  return (
    <div className="lg:w-64 w-32 bg-white border border-gray-200 rounded-lg shadow hover:shadow-lg">
      <Link to={"/channel/" + channelUrl}>
        <div className="relative lg:w-64 lg:h-64 w-32 h-32 overflow-hidden">
          <img
            src={
              "https://api.vlivearchive.com/pfp/" + channel.channelCode + ".png"
            }
            className="absolute inset-0 w-full h-full object-cover rounded-t-lg transition-transform transform hover:scale-105"
            alt={channel.channelName}
            width={76}
            height={76}
          />
        </div>
        <div className="lg:p-5 p-2.5">
          <div>
            <div className="lg:text-xl text-sm font-semibold text-gray-900 truncate">
              {channel.channelName}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default function Home() {
  const [channelList, setChannelList] = useState<Channel[]>([]);

  const [renderNum, setRenderNum] = useState<number>(20);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const fetchNextData = () => {
    setRenderNum(renderNum + 20);
  };

  document.title = "KLIVE Archive";

  useEffect(() => {
    axios
      .get(`https://api.vlivearchive.com/channels?page=${renderNum / 20}`)
      .then(function (response) {
        setChannelList((channelList) => [...channelList, ...response.data]);
      })
      .catch(function (error) {});
  }, [renderNum]);

  if (channelList.length === 0) {
    return "Loading...";
  }

  const handleSearch = (value: string) => {
    setHasMore(value.length === 0);

    if (value.length === 1) return;
    axios
      .get(`https://api.vlivearchive.com/channels/search?q=${value}`)
      .then(function (response) {
        setChannelList(response.data);
      })
      .catch(function (error) {});
  };

  return (
    <>
      <main className="min-h-screen">
        <Header />
        <div className="container mx-auto py-4">
          <SearchGroup handleSearch={handleSearch} />
          <InfiniteScroll
            className="flex flex-wrap gap-4"
            dataLength={renderNum}
            next={fetchNextData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
          >
            {channelList.map((channel, key) => (
              <ArtistCard channel={channel} key={key} />
            ))}
          </InfiniteScroll>
        </div>
      </main>
    </>
  );
}
