import { Caption } from "@/models";
import { useTranslation } from "react-i18next";

function formatSubLabel(caption: Caption) {
  var label = caption.label;
  if (caption.type === "cp") label += " (official)";
  if (caption.type === "auto") label += " (auto)";
  if (caption.type === "fan") label += " (fan)";

  if (caption.fanName !== "") label += ` by ${caption.fanName}`;
  return label;
}

const SubtitlesModal = ({
  subs,
  setSubsOpen,
}: {
  subs: Caption[];
  setSubsOpen: (value: boolean) => void;
}) => {
  function handleClose() {
    setSubsOpen(false);
  }

  function handleDownload(e: Caption, srt: number) {
    // set srt = 0 for vtt files, or srt=1 for srt files
    var url = `https://subtitle.vlivearchive.com/files/${e.file_name}`;
    if (srt === 1) url = url.replaceAll("vtt", "srt");
    window.open(url);
  }

  return (
    <div className="modal--1N199">
      <div className="modal_wrap--1CX43 is_fixed--4RKjs">
        <div className="modal_main--Zh78R">
          <div className="modal_header--3NePP">
            <div className="header_inner--2mKxp">
              <h3 className="header_title--3U_rm">Download Subtitles</h3>
            </div>
          </div>
          <div className="modal_content--1N9Ky">
            <div className="content_inner--KDiaa">
              <div className="option_list--BvEwT -modal--Zu36t">
                <strong className="option_group--2XSCk">VTT format</strong>
                <ul>
                  {subs.map((sub, key) => (
                    <li className="option_item--1XsJy" key={key}>
                      <button
                        type="button"
                        className="option_button--xMcVN"
                        onClick={() => handleDownload(sub, 0)}
                      >
                        <span className="option_button_inner--2I_uR">
                          <span className="option_title--X6IJe">
                            {formatSubLabel(sub)}
                          </span>
                        </span>
                      </button>
                    </li>
                  ))}
                </ul>
                <strong className="option_group--2XSCk">SRT format</strong>
                <ul>
                  {subs.map((sub, key) => (
                    <li className="option_item--1XsJy" key={key}>
                      <button
                        type="button"
                        className="option_button--xMcVN"
                        onClick={() => handleDownload(sub, 1)}
                      >
                        <span className="option_button_inner--2I_uR">
                          <span className="option_title--X6IJe">
                            {formatSubLabel(sub)}
                          </span>
                        </span>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="modal_footer--1fc6a">
            <button
              type="button"
              className="footer_button--1SsnD -cancel--24aAy"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const MoreOptions = ({
  handler,
  handleDownload,
  handleSubs,
  thumbnail_url,
}: {
  handler: () => void;
  handleDownload: () => void;
  handleSubs: () => void;
  thumbnail_url: string;
}) => {
  function downloadThumbnail() {
    window.open(thumbnail_url);
  }

  const { t } = useTranslation();

  return (
    <div className="option_list_wrap--3MIAt -button_layer--1FBE8 -right15--2e4o3">
      <div className="option_list_inner--2clnH">
        <ul className="option_list--3LKju">
          <li className="option_item--116DI -copy--dVBrE">
            <button
              type="button"
              className="option_content--Emqey -button--1xdgv"
            >
              <span className="option_icon--31pt9"></span>
              <span className="option_text--1T9v2">
                <span className="text">
                  <span className="main_text--2S-lP">Copy URL</span>
                </span>
              </span>
            </button>
          </li>
          <li className="option_item--116DI -share--2Esfu">
            <button
              type="button"
              className="option_content--Emqey -button--1xdgv"
            >
              <span className="option_icon--31pt9"></span>
              <span className="option_text--1T9v2">
                <span className="text">
                  <span className="main_text--2S-lP">Share</span>
                </span>
              </span>
            </button>
          </li>
          <li className="option_item--116DI -bookmark--3j7B6">
            <button
              type="button"
              className="option_content--Emqey -button--1xdgv"
            >
              <span className="option_icon--31pt9"></span>
              <span className="option_text--1T9v2">
                <span className="text">
                  <span className="main_text--2S-lP">Bookmark</span>
                </span>
              </span>
            </button>
          </li>
          <li className="option_item--116DI -bookmark--3j7B6">
            <button
              type="button"
              className="option_content--Emqey -button--1xdgv"
              onClick={() => {
                handleDownload();
                handler();
              }}
            >
              <span className="option_icon--31pt9"></span>
              <span className="option_text--1T9v2">
                <span className="text">
                  <span className="main_text--2S-lP">{t('download_video')}</span>
                </span>
              </span>
            </button>
          </li>
          <li className="option_item--116DI -subtitles--vBl9U">
            <button
              type="button"
              className="option_content--Emqey -button--1xdgv"
              onClick={() => {
                handleSubs();
                handler();
              }}
            >
              <span className="option_icon--31pt9"></span>
              <span className="option_text--1T9v2">
                <span className="text">
                  <span className="main_text--2S-lP">Download Subtitles</span>
                </span>
              </span>
            </button>
          </li>
          <li className="option_item--116DI -subtitles--vBl9U">
            <button
              type="button"
              className="option_content--Emqey -button--1xdgv"
              onClick={() => {
                downloadThumbnail();
                handler();
              }}
            >
              <span className="option_icon--31pt9"></span>
              <span className="option_text--1T9v2">
                {/* FIX ICON BEFORE COMMITTING */}
                <span className="text">
                  <span className="main_text--2S-lP">Download Thumbnail</span>
                </span>
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export { SubtitlesModal, MoreOptions };
