import dayjs from "dayjs";

function FMTtimestamp(timestamp: number) {
  if (timestamp == null) return "Unknown Date";
  // vlive was shut down at the end of 2022,
  // and all videos are from prior to then. Therefore, we can assume
  // all dates will be of the format "MMM D, YYYY" and not require the check
  // for the current year.
  const d = dayjs(timestamp);
  // In the past, if the date was from the current year, it could have been
  // formatted as "MMM D" to omit the year for simplicity.
  // However, since all dates are now historical, we include the year.
  return d.format("MMM D, YYYY");
}

/**
 * Converts a given number of seconds into a string formatted as "MM:SS" or "HH:MM:SS".
 * If the input is null, it returns "00:00".
 * 
 * @param {number} s - The number of seconds to be converted.
 * @returns {string} - The formatted time string.
 */
function fmtMSS(s: number): string {
  if (s == null) return "00:00";
  // Convert the input seconds to an integer
  var seconds = s;

  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds - hours * 3600) / 60);
  seconds = seconds - hours * 3600 - minutes * 60;

  var hours_string = hours.toString();
  var minutes_string = minutes.toString();
  var seconds_string = seconds.toString();

  if (minutes < 10) minutes_string = "0" + minutes;
  if (seconds < 10) seconds_string = "0" + seconds;
  if (hours === 0) return minutes_string + ":" + seconds_string;
  return hours_string + ":" + minutes_string + ":" + seconds_string;
}

/**
 * Formats a number into a more readable string representation.
 * If the number is less than 1000, it returns the number as a string.
 * If the number is between 1000 and 1 million, it returns the number in "K" (thousands) format.
 * If the number is 1 million or more, it returns the number in "M" (millions) format.
 * The number is rounded to the nearest integer for "K" format and to one decimal place for "M" format.
 * 
 * @param {number} n - The number to be formatted.
 * @returns {string} - The formatted number as a string.
 */
function formatNum(n: number): string {
  if (n == null) return "0";
  if (n < 1000) return n.toString();
  if (n < 1e6) return Math.round(n / 1000).toString() + "K";
  return Math.round(n / 1e5) / 10 + "M";
}

function get_thumbnail_ext(url: string) {
  if (url == null) return "";
  if (url.charAt(url.length - 4) === ".") return url.slice(-4);
  if (url.charAt(url.length - 5) === ".") return url.slice(-5);
}

export { formatNum, FMTtimestamp, fmtMSS, get_thumbnail_ext };
