import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/landing/index";
import Post from "./pages/video/page";
import Board from "./pages/channel/page";
import Contact from "./pages/Contact";
const routing = (props: any) => {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/post/:post_id" element={<Post />}></Route>
      <Route path="/video/:video_seq" element={<Post />}></Route>
      <Route path="/channel/:channelCode" element={<Board />}></Route>
      <Route path="/contact" element={<Contact />} />
      <Route
        path="/channel/:channelCode/board/:board_id"
        exact
        element={<Board />}
      ></Route>
    </Routes>
  );
};

export default routing;