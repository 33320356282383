import React from "react";
import { Link } from "react-router-dom";
import { Channel } from "@/models";
import { useTranslation } from "react-i18next";

const ChannelArea = ({ channel }: { channel: Channel }) => {
  const { t } = useTranslation();
  
  const channelUrl = channel.channelAlias
    ? channel.channelAlias
    : channel.channelCode;
  return (
    <>
      <Link
        to={"/channel/" + channel.channelCode}
        className="channel_link--3kVMW"
      >
        <span className="thumbnail_wrapper--1FNPC">
          <img
            src={`https://api.vlivearchive.com/pfp/${channel.channelCode}.png`}
            alt=""
            width={76}
            height={76}
          />
        </span>
        <strong className="channel_name--1VIVt">{channel.channelName}</strong>
      </Link>
      <span className="member--36HZL">
        {t("subscribers") + " "}
        <span className="number">{channel.memberCount.toLocaleString()}</span>
      </span>
      <button className="register_button--1QhmP">Join</button>
      <ul className="menu_list--1Xv9-">
        <li className="menu_item--2Pf5K -home--1oM8u">
          <Link to={"/channel/" + channelUrl} className="menu_link--2EiO4">
            <span>HOME</span>
          </Link>
        </li>
        <li className="menu_item--2Pf5K -store--1y_xL">
          <Link to="#" className="menu_link--2EiO4">
            <span>STORE</span>
          </Link>
        </li>
        <li className="menu_item--2Pf5K -chat--WSxZX">
          <Link to="#" className="menu_link--2EiO4">
            <span>CHAT</span>
            <span className="red_dot--5VNZE">
              <em
                className="red_dot--2g2N8"
                style={{ width: "5px", height: "5px" }}
              >
                <span className="blind">new</span>
              </em>
            </span>
          </Link>
        </li>
        <li className="menu_item--2Pf5K -schedule--1ZHsc">
          <Link to="#" className="menu_link--2EiO4">
            <span>SCHEDULE</span>
          </Link>
        </li>
        <li className="menu_item--2Pf5K -my--eR6XS">
          <Link to="#" className="menu_link--2EiO4">
            <span>MY</span>
          </Link>
        </li>
      </ul>
      <div className="button_group--1qPM8">
        <button type="button" className="snb_button--8mSB3 -post--1ZwgT">
          Post
        </button>
      </div>
    </>
  );
};
export default ChannelArea;
