import { KR, JP, GB, TH } from "country-flag-icons/react/3x2";
import { useTranslation } from "react-i18next";
import languageMapping from "./languageMapping";

function LanguageItem({
  language,
  setLanguageModalOpen,
}: {
  language: string;
  setLanguageModalOpen: (open: boolean) => void;
}) {
  const { i18n } = useTranslation();

  const setLanguage = () => {
    let locale = languageMapping[language].locale;
    i18n.changeLanguage(locale);
    localStorage.setItem("language", languageMapping[language].locale);
    setLanguageModalOpen(false);
  };

  return (
    <li>
      <div
        onClick={setLanguage}
        className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-100 hover:bg-gray-200 group hover:cursor-pointer hover:shadow"
      >
        {language === "English" && <GB className="w-6" />}
        {language === "Korean" && <KR className="w-6" />}
        {language === "Japanese" && <JP className="w-6" />}
        {language === "Thai" && <TH className="w-6" />}

        <span className="flex-1 ms-3 whitespace-nowrap">
          {languageMapping[language].caption}
        </span>
      </div>
    </li>
  );
}

function LanguageModal({
  setLanguageModalOpen,
}: {
  setLanguageModalOpen: (open: boolean) => void;
}) {
  return (
    <div className="overflow-y-auto bg-black bg-opacity-50 overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 w-full">
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <h3 className="text-lg font-semibold text-gray-900">
              Language
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center"
              onClick={() => setLanguageModalOpen(false)}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 md:p-5">
            <ul className="my-4 space-y-3">
              <LanguageItem
                language="English"
                setLanguageModalOpen={setLanguageModalOpen}
              />
              <LanguageItem
                language="Korean"
                setLanguageModalOpen={setLanguageModalOpen}
              />
              <LanguageItem
                language="Japanese"
                setLanguageModalOpen={setLanguageModalOpen}
              />
              {/* <LanguageItem
                language="Thai"
                setLanguageModalOpen={setLanguageModalOpen}
              /> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LanguageModal;
