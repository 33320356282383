"use client";
import { useState } from "react";
// import Image from "next/image";
import { Link } from "react-router-dom";
import { KR, JP, GB } from "country-flag-icons/react/3x2";
import LanguageModal from "./languageSelector";
import { useTranslation } from "react-i18next";
import languageMapping from "./languageMapping";

function HeaderButton({ caption, href }: { caption: string; href: string }) {
  return (
    <li>
      <Link className="rounded p-3 hover:bg-gray-100 w-24" to={href}>
        {caption}
      </Link>
    </li>
  );
}
function LanguageSelector({
  setLanguageModalOpen,
}: {
  setLanguageModalOpen: (open: boolean) => void;
}) {
  // const { t, i18n } = useTranslation();
  let cachedLang = "en";
  let storedLang = localStorage.getItem("language");
  if (storedLang != null) cachedLang = storedLang;

  const localeMapping: { [key: string]: string } = {
    en: "English",
    kr: "Korean",
    jp: "Japanese",
    th: "Thai",
  };
  const currentLanguage = localeMapping[cachedLang];

  return (
    <>
      <li onClick={() => setLanguageModalOpen(true)}>
        <Link
          className="rounded hover:bg-gray-100 flex items-center pr-2.5"
          to="#"
        >
          {currentLanguage === "English" && (
            <GB title="United States" className="py-3 mx-2 w-5" />
          )}
          {currentLanguage === "Korean" && (
            <KR title="Korean" className="py-3 mx-2 w-5" />
          )}
          {currentLanguage === "Japanese" && (
            <JP title="Japanese" className="py-3 mx-2 w-5" />
          )}
          {/* {currentLanguage == "Thai" && (
            <TH title="Thai" className="py-3 mx-2 w-5" />
          )} */}
          <span>{languageMapping[currentLanguage]?.caption}</span>
        </Link>
      </li>
    </>
  );
}

function Header() {
  const [languageModalOpen, setLanguageModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <header>
      <nav className="bg-white border-gray-100 px-4 lg:px-6 py-2.5 ">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <Link to="/" className="flex items-center">
            <img
              src="/favicon.png"
              className="mr-3 h-6 sm:h-9"
              alt="KLIVE Logo"
              width={40}
              height={0}
              style={{ width: "auto" }}
            />
            <span className="self-center text-xl font-semibold whitespace-nowrap">
              KLIVE {t("archive")}
            </span>
          </Link>

          <div className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1">
            <ul className="flex flex-col items-center mt-4 font-medium lg:flex-row lg:space-x-4 lg:mt-0">
              <HeaderButton caption={t("home")} href="/" />
              <HeaderButton
                caption={t("status")}
                href="https://status.vlivearchive.com"
              />
              <HeaderButton
                caption="API"
                href="https://docs.vlivearchive.com"
              />
              <HeaderButton caption="Contact" href="/contact" />
              {/* <HeaderButton
                caption="News"
                href="/news"
              />
              <HeaderButton
                caption="Statistics"
                href="https://stats.vlivearchive.com"
              /> */}
              <LanguageSelector setLanguageModalOpen={setLanguageModalOpen} />
            </ul>
          </div>
        </div>
      </nav>
      {languageModalOpen && (
        <LanguageModal setLanguageModalOpen={setLanguageModalOpen} />
      )}
    </header>
  );
}

export default Header;
