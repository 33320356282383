import React from "react";
import { Channel } from "@/models";
import { useTranslation } from "react-i18next";

const ChannelInfo = ({ channel }: { channel: Channel }) => {
  const { t } = useTranslation();

  const thumbnail = `https://api.vlivearchive.com/pfp/${channel.channelCode}.png`;
  return (
    <>
      <h4 className="info_title--jwsgZ">Channel Information</h4>
      <ul className="info_stars_list--FduI_">
        <li className="info_stars_item--lE7Vk -circle--1DV88">
          <span className="blind">{channel.channelName}</span>
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            className="mask_thumbnail--3lmJn"
          >
            <mask id="channel-circle-thumbnail">
              <g transform="translate(-517.000000, -532.000000) translate(360.000000, 532.000000)">
                <circle
                  cx="11"
                  cy="11"
                  r="11"
                  transform="translate(157.000000, 0.000000)"
                ></circle>
              </g>
            </mask>
            <image
              mask="url(#channel-circle-thumbnail)"
              href={thumbnail}
              x="0"
              y="0"
              width="100%"
              height="100%"
              preserveAspectRatio="xMinYMin meet"
            ></image>
          </svg>
        </li>
      </ul>
      <dl className="info_list">
        <div className="info_item--7iTKe">
          <dt className="info_item_title--3jI-M">{t('stars_videos')}</dt>
          <dd className="info_item_detail---lBwJ">
            {channel.fVideoCountOfStar}
          </dd>
        </div>
        <div className="info_item--7iTKe">
          <dt className="info_item_title--3jI-M">{t('views')}</dt>
          <dd className="info_item_detail---lBwJ">
            {channel.fVideoPlayCountOfStar}
          </dd>
        </div>
        <div className="info_item--7iTKe">
          <dt className="info_item_title--3jI-M">{t('likes')}</dt>
          <dd className="info_item_detail---lBwJ">
            {channel.fVideoLikeCountOfStar}
          </dd>
        </div>
        <div className="info_item--7iTKe">
          <dt className="info_item_title--3jI-M">{t('comments')}</dt>
          <dd className="info_item_detail---lBwJ">
            {channel.fVideoCommentCountOfStar}
          </dd>
        </div>
      </dl>
    </>
  );
};
export default ChannelInfo;
