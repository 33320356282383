import Header from "../components/Header";

export default function Contact() {
  return (
    <main className="min-h-screen">
      <Header />
      <div className="max-w-2xl mx-auto p-4">
        <h1 className="text-3xl font-bold mb-6">Contact</h1>
        <iframe
          title="Contact Form"
          src="https://docs.google.com/forms/d/e/1FAIpQLSdIaJ6c3tqmqMyhIXKe59NFekYRNLIcvbOkYFGJ4KfgkLQSHw/viewform?embedded=true"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          className="w-full"
          style={{ height: "calc(100vh - 60px)" }}
        >
          Loading…
        </iframe>
      </div>
    </main>
  );
}
